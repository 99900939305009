<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Cofigurações Gerais</span>
      </template>

      <account-setting-general v-if="options" :general-data="options" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Modificar Senha</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Informações</span>
      </template>

      <account-setting-information v-if="options" :information-data="options" />
    </b-tab>

    <!-- social links -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Verificação</span>
      </template>

      <account-setting-social v-if="options" :social-data="options" />
    </b-tab>

    <!-- notification -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Notificações</span>
      </template>

      <account-setting-notification
        v-if="options"
        :notification-data="options"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import accountService from '@/services/accountService'
import usersService from '@/services/usersService'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: null,
      account: {},
    }
  },
  async mounted() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
    const user = await accountService.getUser()
    const userData = await usersService.getByLogin(user.login)
    this.options = { ...user, ...userData }
  },
}
</script>
