var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currículo",
      "label-for": "bio-area"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "bio-area",
      "rows": "4",
      "placeholder": "Insira seu currículo resumido aqui"
    },
    model: {
      value: _vm.localOptions.bio,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions, "bio", $$v);
      },
      expression: "localOptions.bio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "example-datepicker",
      "label": "Data de Nascimento"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": _vm.configFlatPickr,
      "name": "date",
      "placeholder": "Birth date"
    },
    model: {
      value: _vm.localOptions.dob,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions, "dob", $$v);
      },
      expression: "localOptions.dob"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "countryList",
      "label": "Localização"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "countryList",
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "label": "title",
      "options": _vm.countryOption
    },
    model: {
      value: _vm.localOptions.country,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions, "country", $$v);
      },
      expression: "localOptions.country"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "phone",
      "label": "Celular"
    }
  }, [_c('cleave', {
    staticClass: "form-control",
    attrs: {
      "id": "phone",
      "raw": false,
      "options": _vm.clevePhone,
      "placeholder": "Phone number"
    },
    model: {
      value: _vm.localOptions.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions, "phone", $$v);
      },
      expression: "localOptions.phone"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 mr-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Salvar Modificações ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.resetForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Reverter Modificações ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }