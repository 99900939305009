var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('h6', {
    staticClass: "section-label mx-1 mb-2"
  }, [_vm._v(" Atividade ")]), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "accountSwitch1",
      "checked": _vm.localOptions.commentOnArticle,
      "name": "check-button",
      "switch": "",
      "inline": ""
    }
  }, [_c('span', [_vm._v("Avisar-me com 30 minutos de antecedência quando uma consulta estiver próxima")])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "accountSwitch2",
      "checked": _vm.localOptions.AnswerOnForm,
      "name": "check-button",
      "switch": "",
      "inline": ""
    }
  }, [_c('span', [_vm._v("Avisar-me quando uma consulta for marcada pela Instituição")])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }), _c('h6', {
    staticClass: "section-label mx-1 mt-2"
  }, [_vm._v(" YAPLAY ")]), _c('b-col', {
    staticClass: "mt-1 mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "accountSwitch4",
      "checked": _vm.localOptions.newAnnouncements,
      "name": "check-button",
      "switch": "",
      "inline": ""
    }
  }, [_c('span', [_vm._v("Receber Relatórios de Atualizações do YaPlay pelo E-mail")])])], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1 mt-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Salvar Modificações ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reverter Modificações ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }