<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Nome de Usuário" label-for="account-username">
            <b-form-input
              v-model="optionsLocal.login"
              placeholder="Digite seu Nome de Usuário"
              name="username"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Nome" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.firstName"
              name="name"
              placeholder="Nome"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Clínica" label-for="account-company">
            <b-form-input
              v-model="optionsLocal.company"
              name="company"
              placeholder="Nome da Clínica"
            />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col cols="12" class="mt-75" v-if="!optionsLocal.userActivated">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Seu e-mail não está confirmado. Por favor, verifique sua caixa de
              entrada.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link" @click="sendActive()">
                Reenviar confirmação
              </b-link>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="onSubmit()"
          >
            Salvar Modificações
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reverter Modificações
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import accountService from '@/services/accountService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { cloneObject } from '@/@core/utils/utils'
import usersService from '@/services/usersService'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    async sendActive() {
      // showSpinner()
      await accountService
        .sendActivateAccount(this.generalData.email)
        .then(_ => this.showAlertSucesso(
          'Email enviado com sucesso! Cheque sua caixa de entrada.'
        ))
        .finally(() => hideSpinner())
    },
    async onSubmit() {
      // showSpinner()
      await usersService
        .update({...this.optionsLocal, password: undefined})
        .then(_ => this.showAlertSucesso(
          'Dados salvos com sucesso'
        ))
        .finally(() => hideSpinner())
    },
    showAlertSucesso(mensagem) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: mensagem,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
