var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "file-drag-drop"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap col-12",
    attrs: {
      "v-if": _vm.files.length > 0
    }
  }, _vm._l(_vm.files, function (item) {
    return _c('div', {
      key: item.name,
      staticClass: "badge badge-pill badge-primary mr-1 mt-1",
      staticStyle: {
        "padding": "5px 7px"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c('button', {
      staticClass: "btn",
      staticStyle: {
        "padding": "5px"
      },
      attrs: {
        "title": "Remover"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(item);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "text-white",
      attrs: {
        "icon": "XIcon"
      }
    })], 1)]);
  }), 0), _c('form', {
    ref: "fileform",
    class: {
      over: _vm.isOver
    },
    on: {
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.onUpload.apply(null, arguments);
      }
    }
  }, [_c('input', {
    ref: "inputFile",
    staticClass: "d-none",
    attrs: {
      "multiple": "",
      "type": "file"
    },
    on: {
      "change": _vm.onUpload
    }
  }), _c('span', {
    staticClass: "drop-files"
  }, [_vm._v("Arraste e solte os arquivos aqui")]), _c('div', {
    staticClass: "col-12 text-center"
  }, [_vm._v("Ou")]), _c('div', {
    staticClass: "div"
  }, [_c('b-button', {
    staticClass: "button-right ml-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.inputFile.click();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "UploadIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Selecionar Arquivos")])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }