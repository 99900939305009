var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckCircleIcon",
      "size": "18"
    }
  }), _c('h4', {
    staticClass: "mb-0 ml-75"
  }, [_vm._v(" Verificação ")])], 1), _c('span', [_vm._v("Envie como anexo seu documento de identidade (frente e verso), CPF e Registro Profissional")])]), _c('DragAndDropComponent', {
    on: {
      "update:files": function updateFiles($event) {
        _vm.files = $event;
      }
    }
  })], 1), _c('b-row', [_c('b-col', [_c('b-alert', {
    staticClass: "mt-2",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [!_vm.localOptions.registrationActivated ? _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_c('strong', [_vm._v("Sua Conta ainda não foi verificada! ")]), _vm._v("Envie seus documentos para análise!")])]) : _vm._e()])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 mr-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Salvar Modificações ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 ml-25",
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.resetForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Reverter Modificações ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }