var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-form', {
    staticClass: "mt-2"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nome de Usuário",
      "label-for": "account-username"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Digite seu Nome de Usuário",
      "name": "username"
    },
    model: {
      value: _vm.optionsLocal.login,
      callback: function callback($$v) {
        _vm.$set(_vm.optionsLocal, "login", $$v);
      },
      expression: "optionsLocal.login"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nome",
      "label-for": "account-name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "name",
      "placeholder": "Nome"
    },
    model: {
      value: _vm.optionsLocal.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.optionsLocal, "firstName", $$v);
      },
      expression: "optionsLocal.firstName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "E-mail",
      "label-for": "account-e-mail"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "email",
      "placeholder": "Email"
    },
    model: {
      value: _vm.optionsLocal.email,
      callback: function callback($$v) {
        _vm.$set(_vm.optionsLocal, "email", $$v);
      },
      expression: "optionsLocal.email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Clínica",
      "label-for": "account-company"
    }
  }, [_c('b-form-input', {
    attrs: {
      "name": "company",
      "placeholder": "Nome da Clínica"
    },
    model: {
      value: _vm.optionsLocal.company,
      callback: function callback($$v) {
        _vm.$set(_vm.optionsLocal, "company", $$v);
      },
      expression: "optionsLocal.company"
    }
  })], 1)], 1), !_vm.optionsLocal.userActivated ? _c('b-col', {
    staticClass: "mt-75",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "mb-50",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Seu e-mail não está confirmado. Por favor, verifique sua caixa de entrada. ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('b-link', {
    staticClass: "alert-link",
    on: {
      "click": function click($event) {
        return _vm.sendActive();
      }
    }
  }, [_vm._v(" Reenviar confirmação ")])], 1)])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-2 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onSubmit();
      }
    }
  }, [_vm._v(" Salvar Modificações ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-secondary",
      "type": "reset"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.resetForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" Reverter Modificações ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }